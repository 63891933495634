<div id="main">
	<div id="rectangles">
        <div id="rectangle1">
        </div>
<div id="about_content">
	<div id="about_page_title">
<h1>FAQs...</h1>
	</div>
 	<div id="about_page_text">
<p><span class="small_title">What does the free initial consultation include?</span><br />
The consultation provides an oppurtunity for you and your pet to meet Allan, to discuss the requirements of your pet and to give you the piece of mind that your pet is in save hands.</p>
<p><span class="small_title">What does your insurance cover?</span><br />
Feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leiat vitae. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leiat vitae. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leiat vitae. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leiat vitae.</p>
<p><span class="small_title">Will my dog be save?</span><br />
Every possible safety precaution will be taken to ensure your dog is kept safe and returned to you without any injury or issues. In the event of an emergency we have contingency plans in place, direct contact with a qualified veterinary surgeon and we are fully insured.</p>
<p style="text-align:center;"><a target="_blank" href="./assets/pdfs/faqs.pdf" target="_blank">More FAQs</a></p>
	</div>
</div><!-- !about_content -->
	</div><!-- !rectangles -->
</div><!-- !main -->
