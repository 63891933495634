<div id="main">
	<div id="rectangles">
        <div id="rectangle1">
        </div>
<div id="about_content">
	<div id="about_page_title">
<h1>Sitemap...</h1>
	</div>
 	<div id="about_page_text">
<ul>
	<li><a routerLink="/index">Home</a></li>
	<li><a routerLink="/about">About</a></li>
	<li><a routerLink="/services">Services Offered</a></li>
	<li><a routerLink="/prices">Price List</a></li>
	<li><a routerLink="/area">Area Covered</a></li>
	<li><a routerLink="/contact">Contact</a></li>
	<li><a routerLink="/faqs">FAQs</a></li>
	<li><a routerLink="/legal">Legal Information</a></li>
	<li><a routerLink="/termsconditions">Terms & conditions</a></li>
</ul>
	</div>
</div><!-- !about_content -->
	</div><!-- !rectangles -->
</div><!-- !main -->
