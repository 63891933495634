<div id="main">
	<div id="rectangles">
        <div id="rectangle1">
        </div>
<div id="about_content">
	<div id="about_page_title">
<h1>Legal Information...</h1>
	</div>
 	<div id="about_page_text">
<p class="legal"><b>Terms and Conditions</b><br />
By accessing and using this online application you signify your agreement with the following terms of use and legal restrictions relating to this website and all material contained within it. The site is owned and operated by Old Reliable Pet Care.<br /><br />
<b>Website Materials and Copyright</b><br />
Content in this website, including images, text and logos, is protected by copyright and other intellectual property rights. Any unlawful or unauthorised use of any of the content on this website may violate copyright, trademark or intellectual property laws.<br />
Old Reliable Pet Care permits you to view and download information on this website for personal, non-commercial use only. Any other use of materials on this website is prohibited – including reproduction for purposes other than those noted above, modification, distribution, or republication without the prior written permission of Old Reliable Pet Care.<br /><br />
<b>Accessibility Statement</b><br />
Every effort has been made to produce a standards compliant website. All statements are added to images and CSS is written in a logical manner to produce a sensibly structured application. Although we’ve tested this site extensively there may be some aspect that doesn’t work well, or could work better with an assistive technology. If so, please send us your information and suggestions using our contact form.<br /><br />
<b>Disclaimer</b><br />
Old Reliable Pet Care will not be held liable for any error, omission or failure to update information located on this website.<br /><br />
<b>Linking to this website</b><br />
No permission is required to link to this website. However we do not permit any pages to be loaded into frames on your site.<br /><br />
<b>Governing law</b><br />
This legal notice is governed by and construed in accordance with English law and in the event of any dispute arising in relation to this notice or the website, the courts of England shall have exclusive jurisdiction to settle any dispute.</p>
	</div>
</div><!-- !about_content -->
	</div><!-- !rectangles -->
</div><!-- !main -->
