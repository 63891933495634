<div id="wrap">
  <div id="content">
  <div id="header">
    <div id="header_left">
  <img src="./assets/images/tx1.png" width="182" height="82" alt="dog walking in derbyshire" />
      </div>
    <div id="header_middle">
  <img src="./assets/images/logo.png" width="250" height="113" alt="logo" />
      </div>
    <div id="header_right">
  <img src="./assets/images/tx2.png" width="206" height="56" alt="telephone number" />
      </div>
  </div><!-- !header -->
  <router-outlet></router-outlet>
  <div id="footer">
    <a routerLink="/home"><img src="./assets/images/home1.png" width="72" height="27" alt="home" id="img1" /></a>
    <img src="./assets/images/seperator.png" width="6" height="27" style="margin-left:4px; margin-right:5px;" />
    <a routerLink="/about"><img src="./assets/images/about1.png" width="81" height="27" alt="about" id="img2" /></a>
    <img src="./assets/images/seperator.png" width="6" height="27" style="margin-left:4px; margin-right:5px;" />
    <a routerLink="/services"><img src="./assets/images/services1.png" width="205" height="27" alt="services" id="img3" /></a>
    <img src="./assets/images/seperator.png" width="6" height="27" style="margin-left:5px; margin-right:5px;" />
    <a routerLink="/prices"><img src="./assets/images/prices1.png" width="122" height="27" alt="prices" id="img4" /></a>
    <img src="./assets/images/seperator.png" width="6" height="27" style="margin-left:5px; margin-right:5px;" />
    <a routerLink="/area"><img src="./assets/images/area1.png" width="180" height="27" alt="area covered" id="img5" /></a>
    <img src="./assets/images/seperator.png" width="6" height="27" style="margin-left:4px; margin-right:5px;" />
    <a routerLink="/contact"><img src="./assets/images/contact1.png" width="113" height="27" alt="contact us" id="img6" /></a>
    </div><!-- !footer -->
    <div id="links">
    <p class="p_links"><a routerLink="/sitemap" class="a_links">sitemap</a> &#124; <a class="iframe" routerLink="/faqs" id="example50">FAQs</a> &#124; <a class="iframe" target="_blank" href="./assets/pdfs/terms.pdf" id="example51">terms &amp; conditions</a> &#124; <a routerLink="/legal" class="a_links">legal information</a></p>
    <p class="p_links2">&#169; Old Reliable Pet Care</p>
    </div><!-- !links -->
    </div><!-- !content -->
    </div><!-- !wrap -->
