<div id="main">
	<div id="rectangles">
        <div id="rectangle1">
        </div>
    <div id="prices_content">
	<div id="prices_page_title">
<h1>Price List...</h1>
	</div>
	<div id="prices_text">
<p>For the most up to date charges from Old Reliable Pet Care please click on the PDF link below.</p>
<p>If you have any questions or queries please do not hesitate to contact me.</p>
<p><span style="font-style:italic;">Allan.</span></p>
<p style="text-align:center;"><a target="_blank" href="./assets/pdfs/prices.pdf" target="_blank">Price List</a></p>
    </div>
        </div>
        <div id="rectangle4">
        </div>
	</div><!-- !rectangles -->
</div><!-- !main -->
