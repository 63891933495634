<div id="main">
	<div id="rectangles" style="height: 820px;">
        <div id="services_content">
	<div id="services_page_title">
<div id="services_page_title_left">
<h1>Services: Home Services...</h1>
</div>
<div id="services_page_title_right">
<img src="./assets/images/nav2.jpg" id="img71" width="100" height="35" border="0" usemap="#Mapit2" />
<map name="Mapit2">
<area onMouseOver="MM_swapImage('img71','','images/nav1.jpg',0)" onMouseOut="MM_swapImgRestore()" shape="rect" coords="56,5,93,34" routerLink="/services-walking">
</map>
</div>
	</div>
	<div id="services_text" style="height: 820px;">
<ul class="walking" style="padding-bottom:20px; padding-top:10px;">
	<li><span class="walk_type3">Walkies While You Work!</span> (the 8 hour daytime service)<span class="money2">&pound;30</span>
<p class="list2">Designed to be flexible around your working or social commitments Walkies While You Work! provides a full care package for your dog which means you have complete peace of mind when you’re away. It covers an 8 hour period of your choosing between the times of 8am – 8pm and guarantees not only that your dog will be fed and cared for according to their needs but also includes <a target="_parent" routerLink="/services-walking">2xWalkies!</a> or <a target="_parent" routerLink="/services-walking">1xExpedition!</a> service within the price.
</p>
<p class="list2">This service is also available to cats and any other domestic pets. Walkies While You Work! service guarantees that within the 8 hour period your pet will not be left for a period longer than 3 hours.
</li>
	<li><span class="walk_type3">In the Dog House!</span> (the 24 hour service for your dog)<span class="money2">&pound;15</span>
<p class="list2">Our 24 hour service takes care of all of your dog’s needs and checks that your property is kept save and secure. Comes inclusive of <a class="intextlink2" routerLink="/services-walking">2xHairy Hikers!</a> walks in the price.</p>
<p class="list2">Unfortunately for your dog, sometimes their favourite people have to go away for longer than they could bear to imagine. On such occasions, whether the boss is on holiday or away on business, Old Reliable is happy to be In the Dog House! taking care of all of your dog’s needs. In the Dog House! not only ensures your best friends are kept relaxed and content in their own familiar surroundings but will also make sure that your property is safe, secure and well maintained whilst you are away.
</p>
<p class="list2">Our 24 hour service is tailored to caring for all of your dog’s basic needs such as feeding, grooming and interaction, and comes inclusive of <a target="_parent" routerLink="/services-walking">2xHairy Hikers!</a> walks in the price. However, Old Reliable is aware that you may have a few basic needs of your own and is happy to undertake any light house or gardening chores you require to be carried out in your absence.
</p>
<p class="list2">In the Dog House! service guarantees that within the 24 hour period your dog will not be left for a period longer than 4 hours and that Old Reliable will be standing guard on your premises every night you’re away from at least 8pm (dependent on your requirements). Household/gardening chores may be discussed with Old Reliable during consultation but may include the following: vacuuming, light weeding, mowing the lawn, taking delivery of goods, handling phone calls etc. Please note that Old Reliable is happy to house sit any domestic pets and that the price is the same for up to 2 dogs from the same household.
</p>
</li>
	<li><span class="walk_type3">In the Cat House!</span> (the 24 hour service for your cat)<span class="money2">&pound;40</span>
<p class="list2">Our 24 hour care service for your feline friend(s) ensures that food bowels are full, litter trays are empty and that your cat is kept well entertained for a 24 hour period. We will also make sure that your house remaines save and secure whilst you are away.</p>
<p class="list2">This service is committed to caring for your feline friends on a 24 hour basis when you’re away. We’ll take care of all your little fur-ball’s frolics, foibles or follies so you can rest assured that you’ll have one content kitty waiting to greet you on your return. With Old Reliable making sure litter trays are emptied and food bowls are full, In the cat House! will give the whole pride peace of mind and provides you with the <span style="font-style:italic">&quot;purrrfect&quot;</span> solution to caring for your cat.
</p>
<p class="list2">In the Cat House! service guarantees that within the 24 hour period your cat will not be left for a period longer than 5 hours and that Old Reliable will be standing guard on your premises every night you’re away from at least 8pm (dependent on your requirements). Household/ gardening chores may be discussed with Old Reliable during consultation but may include the following: vacuuming, light weeding, mowing the lawn, taking delivery of goods, handling phone calls etc. Please note that Old Reliable is happy to house sit any domestic pets and that the price is the same for up to 3 cats from the same household.
</li>

	<li><span class="walk_type3">Daily Check-Up</span> (the drop in service for your pet)<span class="money2">&pound;5</span>
<p class="list2">If you only require someone to pop in to feed your fury friends, empty a litter tray or let them out in the garden for a <span style="font-style:italic;">&quot;quick sniff&quot;</span> then Daily Check-up is designed to do just that. Old Reliable is happy to call in on any domestic pet which may require just a few minutes care during the day and will be happy to discuss any needs you might need meeting.</p>
</li>

	<li style="padding-bottom:0px;"><span class="walk_type3">Kindergarten Cop</span> (the puppy/kitten monitoring service)<span class="money2">&pound;6</span>
	<p class="list2">This 20 minute service is designed to keep an eye on puppies and kittens, to check they aren't misbehaving, provide them with socialisation and help with toilet training. This service utilises Old Reliable’s years spent in the police force to make sure your little ones are not misbehaving as soon as your back is turned! However, whilst the long arm of the law may be used to ensure your new recruits are keeping up with their training, it may also be used to stroke, play and interact with them at the same time to ensure they are developing into well socialised citizens!</p>
</li>

</ul>
	</div>
        </div>
        <div id="rectangle4">
        </div>
	</div><!-- !rectangles -->
</div><!-- !main -->
