<div id="main">
	<div id="rectangles">
<div id="map">
</div>
<div id="area_content">
	<div id="area_page_title">
<h1>Area Covered...</h1>
	</div>
 	<div id="area_page_text">
<p style="font-size:13px;">Old Reliable covers the five main regions and their surrounding areas as detailed below. However, if you don’t see the area in which you live that does not necessarily mean we will not be able to take care of your pet. If you are a resident who lives close to the regions listed, and you would like to take advantage of the services offered by Old Reliable, please don’t hesitate to pick up the phone as we would be happy to discuss the options available to you. We look forward to your call!
</p>
<p style="font-size:13px; line-height:16px; padding-top:20px;"><strong>1. Holbrook:</strong><br />Bargate, Makenay, Milford</p>
<p style="font-size:13px; line-height:16px; padding-top:8px;"><strong>2. Duffield:</strong><br />Cowers Lane, Hazelwood, Idridgehay, Muggington, Shottlegate, Turnditch, Weston Underwood, Windley</p>
<p style="font-size:13px; line-height:16px; padding-top:8px;"><strong>3. Belper:</strong><br />Ambergate, Blackbrook, Crich, Heage, Nether Heage</p>
<p style="font-size:13px; line-height:16px; padding-top:8px;"><strong>4. Kilburn:</strong><br />Coxbench, Denby, Horsley, Horsley Woodhouse, Little Eaton, Smalley</p>
<p style="font-size:13px; line-height:16px; padding-top:8px;"><strong>5. Derby:</strong><br />Allestree, Breadsall, Darley Abbey, Quarndon</p>
	</div>
</div><!-- !about_content -->
	</div><!-- !rectangles -->
</div><!-- !main -->
