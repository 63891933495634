<div id="main">
	<div id="rectangles" style="height: 760px;">
        <div id="services_content" style="height: 760px;">
	<div id="services_page_title">
<div id="services_page_title_left">
<h1>Services: Dog Walking...</h1>
</div>
<div id="services_page_title_right">
<img src="./assets/images/nav1.jpg" id="img70" width="100" height="35" border="0" usemap="#Mapit" />
<map name="Mapit">
<area onMouseOver="MM_swapImage('img70','','images/nav2.jpg',0)" onMouseOut="MM_swapImgRestore()" shape="rect" coords="2,4,49,34" routerLink="/services-home">
</map>
</div>
	</div>
	<div id="services_text" style="height: 760px;">
    <ul class="walking" style="padding-bottom:20px; padding-top:10px;">

    	<li><span class="walk_type3">Dogs dinner</span> (minimum 10mins walk)<span class="money2">&pound;4</span>
        <p class="list">If you only require someone to pop in to feed your fury friends and/or let them out in the garden for a quick sniff then "Dogs Dinner" is designed to do just that.</p>
      </li>

    	<li><span class="walk_type3">Kindergarten Cop</span> (the puppy/kitten monitoring service)<span class="money2">&pound;6</span>
    	  <p class="list2">This 20 minute service is designed to keep an eye on puppies and kittens, to check they arn't misbehaving, provide them with socialisation and help with toilet training.</p>
      </li>

    	<li><span class="walk_type3">Walkies!</span> (minimum 20mins walk)<span class="money2">&pound;6</span>
        <p class="list">Our entry level dog walk that ensures your dog gets a full 20 minutes exercise according to their needs to be agreed with you the owner.</p>
      </li>

    	<li><span class="walk_type3">Hairy Hikers!</span> (minimum 30mins walk)<span class="money2">&pound;8</span>
        <p class="list">Our mid-length dog walking service provides your dog with ample opportunity to wear themselves out whilst you’re away. We would recommend Hairy Hikers for energetic dogs who enjoy the chance to get into their stride whilst out for a walk.</p>
      </li>

    	<li><span class="walk_type3">Expedition!!</span> (minimum 45mins walk &amp; exploration)<span class="money2">&pound;11</span>
        <p class="list">Not for the faint hearted, Expedition is for dogs who love nothing more than exploring new and exciting territories. After your dog has exhausted themselves playing, frolicking, swimming and fetching - according to what you know your dog will enjoy - you can expect to find your dog sound asleep when they return from being out on Expedition!</p>
      </li>

    	<li><span class="walk_type3">Pack patrol</span> (minimum 45mins walk &amp; exploration)<span class="money2">&pound;10</span>
        <p class="list">Not for the faint hearted, Expedition is for dogs who love nothing more than exploring new and exciting territories. After your dog has exhausted themselves playing, frolicking, swimming and fetching - according to what you know your dog will enjoy - you can expect to find your dog sound asleep when they return from being out on Expedition!</p>
      </li>

    	<li><span class="walk_type3">Top dog</span> <span class="money2">&pound;10 discount</span>
        <p class="list">For our regular dogs that book an expedition on a monthly basis</p>
      </li>

    	<li><span class="walk_type3">Doggy day care</span> (the 24 hour service for your dog)<span class="money2">&pound;15</span>
        <p class="list2">Our 24 hour service takes care of all of your dog’s needs and checks that your property is kept save and secure. Comes inclusive of 2xHairy Hikers! walks in the price.</p>
      </li>

    	<li><span class="walk_type3">Sleep Over</span> (subject to availabiltiy)<span class="money2">&pound;20</span>
        <p class="list2">A 24 hour care package that takes care of all your dogs needs and is inclusive of two walks. This can be in our home living alongside and playing with our dog Alfie, or in the comfort of their own home.</p>
      </li>

    	<li style="padding-bottom:0px;"><span class="walk_type3">In the dog house</span> (subject to availabiltiy)<span class="money2">&pound;100</span>
        <p class="list2">Enjoy your week away from home safe in the knowledge that you pet is being cared for in a family environment.</p>
      </li>

    </ul>
	</div>
        </div>
        <div id="rectangle4">
        </div>
	</div><!-- !rectangles -->
</div><!-- !main -->
