<div id="main">
	<div id="rectangles">
        <div id="services_content">
	<div id="services_page_title">
<h1>Services Offered...</h1>
	</div>
	<div id="services_text">
<p>Old Reliable is committed to providing professional and practical help for you and your pet and understands the need to fit around your schedule! You can feel free to call us at any time convenient for you and can always expect both fantastic value for money and a considerate attitude towards your home.  As for your pet, whether they have behavioural problems or are getting long in the tooth, they may expect to be handled appropriately to their own needs in a stress free and caring way. At Old Reliable our mission is to provide you with the peace of mind that we know is only possible when you have a responsible and dedicated service that you can trust. That is also why we will not only record what your pet(s) have been up to following our visit, but we will also update you with texts, photo messaging, or email, should you wish. </p>
<p>Please find our full list of services below and if you wish for clarification on any point don’t hesitate to call or alternatively you may look at our <a class="iframe" id="example52" href="popups/faqs.html">FAQs</a>. Please note also that an <strong>initial consultation is free</strong> as Old Reliable understands the importance of discussing specific needs you or your pet may have and for establishing trust between all parties at the earliest opportunity.</p>
<div id="services">
	<div id="services_left">
<a routerLink="/services-walking" onMouseOver="MM_swapImage('img50','','images/service11.jpg')" onMouseOut="MM_swapImgRestore()"><img id="img50" onMouseOver="MM_swapImage('img50','','images/service11.jpg')" onMouseOut="MM_swapImgRestore()" src="./assets/images/service1.jpg" width="166" height="100" height="99" style="margin-left:95px; margin-top:29px;" /></a>
	</div>
	<div id="services_right">
<a routerLink="/services-home" onMouseOver="MM_swapImage('img51','','images/service22.jpg')" onMouseOut="MM_swapImgRestore()"><img id="img51" onMouseOver="MM_swapImage('img51','','images/service22.jpg')" onMouseOut="MM_swapImgRestore()" src="./assets/images/service2.jpg" width="166" height="100" style="margin-left:35px; margin-top:29px;" /></a>
	</div>
</div>
</div>
        </div>
        <div id="rectangle4">
        </div>
	</div><!-- !rectangles -->
</div><!-- !main -->
