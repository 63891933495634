<div id="main">
	<div id="rectangles">
        <div id="rectangle1">
        </div>
    <div id="prices_content">
	<div id="prices_page_title">
<h1>Contact Us...</h1>
	</div>
	<div id="prices_text">
<p>If you have any questions or queries please do not hesitate to contact me using any of the means below.</p>
<p><span style="font-style:italic;">Old Reliable.</span></p>
<div id="contact_detail">
	<div id="contact_detail_left">
<h3>Telephone:</h3>
	</div>
	<div id="contact_detail_right">
<h4>07749 053194 <span style="font-style:italic;">or</span><br />01332 880836</h4>
	</div>
</div>
<div id="contact_detail">
	<div id="contact_detail_left" style="height:45px;">
<h3>Email:</h3>
	</div>
	<div id="contact_detail_right" style="height:25px;">
<h4>ascales48@hotmail.co.uk</h4>
	</div>
</div>
    </div>
        </div>
        <div id="rectangle4">
        </div>
	</div><!-- !rectangles -->
</div><!-- !main -->
