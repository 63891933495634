<div id="main">
    <div id="rectangles">
          <div id="rectangle1">

          </div>
          <div id="rectangle2">

          </div>
          <div id="rectangle3">

          </div>
          <div id="rectangle4">

          </div>
    </div><!-- !rectangles -->
  </div><!-- !main -->

