import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './routes/about/about.component';
import { AreaComponent } from './routes/area/area.component';
import { ContactComponent } from './routes/contact/contact.component';
import { FaqsComponent } from './routes/faqs/faqs.component';
import { HomeComponent } from './routes/home/home.component';
import { LegalComponent } from './routes/legal/legal.component';
import { PricesComponent } from './routes/prices/prices.component';
import { ServicesHomeComponent } from './routes/services-home/services-home.component';
import { ServicesWalkingComponent } from './routes/services-walking/services-walking.component';
import { ServicesComponent } from './routes/services/services.component';
import { SitemapComponent } from './routes/sitemap/sitemap.component';

const routes: Routes = [

  { path: 'about', component: AboutComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'services-home', component: ServicesHomeComponent },
  { path: 'services-walking', component: ServicesWalkingComponent },
  { path: 'prices', component: PricesComponent },
  { path: 'area', component: AreaComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'sitemap', component: SitemapComponent },

  { path: '', component: HomeComponent },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [
    AboutComponent,
    FaqsComponent,
    LegalComponent,
    AreaComponent,
    PricesComponent,
    SitemapComponent,
    HomeComponent,
    ServicesComponent,
    ContactComponent,
    ServicesHomeComponent,
    ServicesWalkingComponent
  ]
})
export class RoutingModule {}
