
  <div id="main">
    <div id="rectangles">
          <div id="rectangle1">
          </div>
  <div id="about_content">
    <div id="about_page_title">
  <h1>About Old Reliable Pet Care...</h1>
    </div>
     <div id="about_page_text">
  <p style="padding-top:30px;" class="titlep">Who is Old Reliable?</p>
  <p style="padding-top:10px;">Old Reliable is a fully insured and professional pet care service that offers dog walking, puppy visits, and related services. It was set up by retired police officer Allan Scales in order to combine his longstanding love of animals and years spent serving the public. Based in the village of Holbrook in Derbyshire, Old Reliable serves the needs of pet owners who live in the <a class="intextlink" routerLink="/area">surrounding area</a> and provides a committed, flexible and caring approach to looking after your pets as and when you need it.</p>
  <p style="padding-top:30px;" class="titlep">Why Old Reliable? 3 simple reasons...</p>
  <p style="padding-top:10px;">1. Love of all creatures great &amp; small (<a class="iframe" target="_blank" href="./assets/pdfs/why-old-reliable.pdf" id="example1">click here for more info</a>).</p>
  <p style="padding-top:10px;">2. Quite simple, you can count on us (<a class="iframe" target="_blank" href="./assets/pdfs/why-old-reliable.pdf" id="example2">click here for more info</a>).</p>
  <p style="padding-top:10px;">3. We've got you in mind (<a class="iframe" target="_blank" href="./assets/pdfs/why-old-reliable.pdf" id="example3">click here for more info</a>).</p>
  <p style="padding-top:30px;" class="titlep">Photos...</p>
  <p style="padding-top:10px;"><a rel="example_group" target="_blank" href="./assets/pdfs/photos.pdf" title="Old Reliable himself">click here for photos</a>.
  <a rel="example_group" href="images/oldreliable2.jpg" title="The gang"></a>
  <a rel="example_group" href="images/oldreliable3.jpg" title="Otto"></a>
  <a rel="example_group" href="images/oldreliable4.jpg" title="Mia"></a>
  <a rel="example_group" href="images/oldreliable5.jpg" title="Alfie"></a>
  <a rel="example_group" href="images/oldreliable6.jpg" title="Leia"></a>
  <a rel="example_group" href="images/oldreliable7.jpg" title="Stan & Rosie"></a>
  <a rel="example_group" href="images/oldreliable8.jpg" title="Phoebe"></a>
  </p>
    </div>
  </div><!-- !about_content -->
    </div><!-- !rectangles -->
  </div><!-- !main -->
